import React from "react";

export default () => {
  return (
    <div>
      <h1>Vestaboard Simulator</h1>
      <p>
        Please upgrade to the new simulator by visiting settings / publish in
        the Vestaboard web app.
      </p>
    </div>
  );
};
